import {
  useCreateAsset,
  useMarkAssetAsUploaded,
  useUploadToPresignedUrl,
} from "@api/asset";
import { useUpdateUserExperiences } from "@api/experience";
import { useSubmitApplication, useUpdateUser } from "@api/user";
import { AssetPurpose, AssetType } from "@interfaces/asset";
import { IUpdateUser } from "@interfaces/user";
import * as Sentry from "@sentry/react";
import { useErrorStore } from "@stores/error";
import useUserStore from "@stores/user";
import { useState } from "react";

import displayToast from "@/utils/toasts";

// Helper function to convert data URL to File
const dataURLtoFile = (url: string, filename: string): File => {
  if (!url) return new File([], filename);
  const [header, data] = url.split(",");
  const mime = header.match(/:(.*?);/)![1];
  const binary = atob(data);
  const array = Uint8Array.from(binary, (char) => char.charCodeAt(0));
  return new File([array], filename, { type: mime });
};

const useSubmit = () => {
  const { mutateAsync: createAsset } = useCreateAsset();
  const { mutateAsync: uploadToPresignedUrl } = useUploadToPresignedUrl();
  const { mutateAsync: markAssetAsUploaded } = useMarkAssetAsUploaded();
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutateAsync: updateUserExperiences } = useUpdateUserExperiences();
  const { mutateAsync: submitApplication } = useSubmitApplication();

  const [success, setSuccess] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useUserStore((state) => state.user);
  const instaHandle = useUserStore((state) => state.instaHandle);
  const setUser = useUserStore((state) => state.setUser);
  const photo = useUserStore((state) => state.photo);
  const { setError, setIsAPIError } = useErrorStore();

  const handleError = (message: string) => {
    setError(message, "Please try again after some time.");
    setIsAPIError(true);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Upload photo if exists and profile image url is not present
      let profileImageUrl = user?.profile_image_url ?? "";

      if (!user?.profile_image_url) {
        // Create asset
        const assetData = await createAsset({
          purpose: AssetPurpose.PROFILE_PICTURE,
          type: AssetType.IMAGE,
        }).catch((error) => {
          handleError(error.message || "Failed to create asset");
          throw error;
        });
        if (photo && assetData) {
          const photoFile = dataURLtoFile(photo as string, "profile.jpg");
          const url = assetData.assets[0]?.presigned_url;
          profileImageUrl = url.split("?")[0];
          setUser({ ...user, profile_image_url: profileImageUrl });
          await uploadToPresignedUrl({ url, file: photoFile }).catch(
            (error) => {
              handleError(error.message || "Failed to upload photo");
              throw error;
            }
          );
          await markAssetAsUploaded(assetData.assets[0]?.asset?.id).catch(
            (error) => {
              handleError(error.message || "Failed to mark asset as uploaded");
              throw error;
            }
          );
        }
      }

      // Update user profile
      const userData: IUpdateUser = {
        name: user?.name ?? "",
        date_of_birth: user?.date_of_birth ?? "",
        gender: user?.gender ?? null,
        profile_image_url: profileImageUrl,
        extras_json: { instagram_handle: instaHandle },
      };
      await updateUser(userData).catch((error) => {
        handleError(error.message || "Failed to update user profile");
        throw error;
      });

      // Step 5: Update user interested experiences
      const experiences = user?.interested_experiences ?? [];
      if (experiences.length > 0) {
        await updateUserExperiences(experiences).catch((error) => {
          handleError(
            error.message || "Failed to update user interested experiences"
          );
          throw error;
        });
      }

      // Submit application
      await submitApplication().catch((error) => {
        handleError(error.message || "Failed to submit application");
        throw error;
      });
      setSuccess(true);
    } catch (error: any) {
      Sentry.captureException(error);
      // Display specific errors
      setErrorOccurred(true);
      if (error?.response?.status === 403) {
        displayToast("Error in onboarding flow", { type: "error" });
      } else {
        handleError("Error in submission process");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSubmit, success, errorOccurred, isLoading };
};

export default useSubmit;
